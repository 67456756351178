import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import { Nav } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Button, Modal } from 'react-bootstrap';
import { FaDiscord, FaTwitter, FaCoffee } from 'react-icons/fa';
import { AiFillMessage } from 'react-icons/ai';
import { Text, Linking } from 'react-native'


export default function Banner() {
  const [showModal, setShowModal] = useState(false) //render when the page first opens
  const [showMessage, setShowMessage] = useState(false)

  return (
    <Navbar variant="dark">
      <Navbar.Brand href="/">
        <h1 style={{ paddingLeft: "15%" }}>
          <Image
            style={{ width: 50, height: 50 }}
            src="https://assets.valorantesports.com/val/vct-logo.21d0c9ddeb.svg"
          />
          { } Fantasy VCT
        </h1>
      </Navbar.Brand>

      <div style={{ paddingLeft: "6%" }}>
        <Nav className="me-auto">

          <Button style={{ maxHeight: "40px" }} variant="warning" onClick={() => setShowModal(true)}>
            Rules
          </Button>
          <Button style={{ maxHeight: "40px" }} variant="outline-warning" onClick={() => setShowMessage(true)}>
            <AiFillMessage size={25}/>
          </Button>

          <Nav.Link href="/">&nbsp;&nbsp; My Team &nbsp;&nbsp;</Nav.Link>
          <Nav.Link href="/leaderboard">&nbsp;&nbsp; Leaderboard &nbsp;&nbsp;</Nav.Link>
          <Nav.Link href="/players">&nbsp;&nbsp; Players &nbsp;&nbsp;</Nav.Link>
          {/* <Nav.Link href="/transfers">&nbsp;&nbsp; Transfers &nbsp;&nbsp;</Nav.Link> */}
          <Nav.Link href="https://discord.gg/FvAgKUYCbP">&nbsp;&nbsp;
            <FaDiscord size={30} />
            &nbsp;&nbsp;
          </Nav.Link>
          <Nav.Link href="https://twitter.com/FantasyVCT">&nbsp;&nbsp;
            <FaTwitter size={30} />
            &nbsp;&nbsp;
          </Nav.Link>
          <Nav.Link href="https://ko-fi.com/fantasyvct">&nbsp;&nbsp;
            <FaCoffee size={30} />
            &nbsp;&nbsp;
          </Nav.Link>
          {/* <Nav.Link href="https://emea.fantasyvct.com" >&nbsp;&nbsp; <Image style ={{height: 30}} src="https://i.imgur.com/N8hqd6g.png"/> &nbsp;&nbsp;</Nav.Link> */}


          <Modal show={showMessage} onClick={() => setShowMessage(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Messages & Updates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <strong>Jun 5: </strong> Welcome back to Fantasy VCT! This tab will continue to be where we communicate on the site itself. 
                If you want to chat to the team, other participants, or give feedback/input, join our <Text style ={{color: 'blue', fontSize: 15}}onPress={() => Linking.openURL("https://discord.gg/FvAgKUYCbP")}> Discord Server </Text>
                <br/>
                <br/>
                
                Members of the server got the chance to vote on player prices for this competition, which will continue in the future so make sure to get in and give your input!
                <br/>
                <br/>
                We also have a <Text style ={{color: 'blue', fontSize: 15}}onPress={() => Linking.openURL("https://twitter.com/FantasyVCT")}> Twitter </Text> where we post general updates.
                <br/>
                <br/>
                As always, thanks for playing and good luck!
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowMessage(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showModal} onClick={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Rules</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h3 style={{ textAlign: "center" }}>Welcome to Fantasy VCT</h3>
                <br />
                <p> <em>
                    *Please read in its entirety*
                </em> </p>

                <h5> <strong>The Fantasy VCT format: </strong></h5>
                <p>
                    - You have the ability to choose a custom starting lineup of 5 players 
                    (Duelist, Initiator, Controller, Sentinel, and Flex).
                    <br />
                    -The unselected 3 players will be placed on the bench and will not earn points for that match day.
                    <br />
                    -Each position can be filled by a player with that role listed as you choose your initial team.
                    The Flex role can be filled by anyone.
                    <br />
                    -You will also choose a team captain for each separate match day lineup who will earn 1.5x points for that match.
                    <br/>
                </p>
                <br />
                <p>
                    -The Group Stage is cumulative and averaged across all group matches, meaning the lineup you choose will the lineup for all of groups.
                    Starting with the playoffs, each match a team plays will be separate match days.
                    <br />
                    -Every lineup will be separate from each other, meaning you can make changes to your team however you'd like.
                    <br />
                    -You can make changes to each lineup until the timer at the bottom of the page expires, indicating the start of that selected match day.
                    <br />
                    -From that point on, that specific match day lineup is locked in. 
                    <br />
                    -Editing your team is straightforward: for each player you select, you are able to "Swap" with players who are eligible. 
                    Note that both players in question must be able to fit in the other player's role.
                </p>
                <br />
                <p>
                    -The "Total" tab will represent how many points each player has earned for you specifically.
                    This means both missing points from benched players as well as additional points from captained players will be reflected.
                    <br />
                    -This is also the case when you view other teams on the "Leaderboard" page. 
                    The "Players" page will show the raw total points each player has earned in the tournament.

                </p>
                <h5> <strong>How the team selection works: </strong></h5>
                <p>
                    - You have a salary budget of 14000 credits to recruit your players.<br />
                    - You must recruit 2 players for each role (Duelist, Initiator, Sentinel, Controller).<br />
                    - The roles each player can be recruited as is listed.<br />
                </p>
                <h5><strong>How the fantasy points work: </strong></h5>
                <p>
                    - Players earn points through combat stats (K/D/A, FK/FD, ADR) and round stats (Rounds won, Rounds lost).
                    <br />
                    - The points tally for a match day is an average of points earned per map played.
                    <br />
                    - Points Calculation: <br />
                    <em>
                    [+10 Points per kill, +5 Points per assists, +10 Points per First Kill, +ADR/10] <br />
                    [-5 Points per death, -10 Points per First Death] <br />
                    [+5 Points per Round win, -5 Points per Round loss] <br />
                    <br />
                    </em>
                </p>
                <br />
                <br />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
          </Modal>



        </Nav>
      </div>
    </Navbar >
  )
}