import React, { useEffect, useState } from "react";
import { doesTeamNameExist, getPlayerList, updatePicked } from './Firebase.js';
import PlayerCardDisplayBlock from './PlayerCardDisplayBlock';
import Banner from './Banner';
import { Button, Modal } from 'react-bootstrap';
import Image from "react-bootstrap/Image";

export const getImageLink = (str) => {
    if (str === "Cloud9") {
        return "https://owcdn.net/img/60cedb25c2016.png"
    } else if (str === "Sentinels") {
        return "https://owcdn.net/img/60cedc3d59613.png"
    } else if (str === "Envy") {
        return "https://owcdn.net/img/5f3ca822464a3.png"
    } else if (str === "100 Thieves") {
        return "https://owcdn.net/img/603c00d5c5a08.png"
    } else if (str === "Version1") {
        return "https://owcdn.net/img/6028427b5bbd6.png"
    } else if (str === "XSET") {
        return "https://owcdn.net/img/5f9725636681d.png"
    } else if (str === "The Guard") {
        return "https://owcdn.net/img/61855ee4635e2.png"
    } else if (str === "NRG Esports") {
        return "https://owcdn.net/img/5f7b9ff821d6d.png"
    } else if (str === "Evil Geniuses") {
        return "https://owcdn.net/img/6011e22fc5be3.png"
    } else if (str === "Knights") {
        return "https://owcdn.net/img/604022f368433.png"
    } else if (str === "Luminosity") {
        return "https://owcdn.net/img/5f36ec1f4ab21.png"
    } else if (str === "Rise") {
        return "https://owcdn.net/img/5f3874cbd2156.png"
    } else if (str === "OpTic Gaming") {
        return "https://owcdn.net/img/62058299eef09.png"
    } else if (str === "DRX") {
        return "https://owcdn.net/img/61e01b462a919.png"
    } else if (str === "FNATIC") {
        return "https://owcdn.net/img/60cedbc18c17e.png"
    } else if (str === "G2 Esports") {
        return "https://owcdn.net/img/5f3cbb213e5de.png"
    } else if (str === "KRU Esports") {
        return "https://owcdn.net/img/609c5ef42f997.png"
    } else if (str === "LOUD") {
        return "https://owcdn.net/img/61c2286d2221b.png"
    } else if (str === "Ninjas in Pyjamas") {
        return "https://owcdn.net/img/600f8bb766cb7.png"
    } else if (str === "Paper Rex") {
        return "https://owcdn.net/img/5f60f06061ca7.png"
    } else if (str === "Team Liquid") {
        return "https://owcdn.net/img/60cedbff184c0.png"
    } else if (str === "XERXIA Esports") {
        return "https://owcdn.net/img/61e40deb3eb5d.png"
    } else if (str === "ZETA DIVISION") {
        return "https://owcdn.net/img/60e712523df78.png"
    } else if (str === "FaZe Clan") {
        return "https://owcdn.net/img/61f6177ab67b3.png"
    } else if (str === "TSM") {
        return "https://owcdn.net/img/60aeaa9e6ffc8.png"
    } else if (str === "Ghost Gaming") {
        return "https://owcdn.net/img/5fff8b670ad33.png"
    } else if (str === "FunPlus Phoenix") {
        return "https://owcdn.net/img/5feab33100c1d.png"
    } else if (str === "M3 Champions") {
        return "https://owcdn.net/img/623a720ec4c65.png"
    } else if (str === "Guild Esports") {
        return "https://owcdn.net/img/6279315a0b968.png"
    } else if (str === "Natus Vincere") {
        return "https://owcdn.net/img/60dc478295d73.png"
    } else if (str === "BBL Esports") {
        return "https://owcdn.net/img/60df0bf7993ab.png"
    } else if (str === "OG LDN UTD") {
        return "https://owcdn.net/img/627933f8af668.png"
    } else if (str === "Acend") {
        return "https://owcdn.net/img/611a8c6dc5dac.png"
    } else if (str === "FOKUS") {
        return "https://owcdn.net/img/627934cd0b505.png"
    } else if (str === "BIG") {
        return "https://owcdn.net/img/5f1362059d3ce.png"
    } else if (str === "Leviatan") {
        return "https://owcdn.net/img/61b88892a9814.png"
    } else if (str === "FURIA") {
        return "https://owcdn.net/img/600e14f63980b.png"
    } else if (str === "EDward Gaming") {
        return "https://owcdn.net/img/62c8204fbb29d.png"
    } else if (str === "BOOM Esports") {
        return "https://owcdn.net/img/629f1be226015.png"
    } else if (str === "FUT Esports") {
        return "https://owcdn.net/img/632be99c96c64.png"
    } else if (str === "Attacking Soul Esports") {
        return "https://owcdn.net/img/62bd505e7eb4e.png"
    } else if (str === "T1") {
        return "https://owcdn.net/img/62fe0b8f6b084.png"
    } else if (str === "Giants Gaming") {
        return "https://owcdn.net/img/61dfcad6a6145.png"
    } else if (str === "Bilibili Gaming") {
        return "https://owcdn.net/img/63f25d72216c1.png"
    }

    else if (str === "Controller") {
        return "https://static.wikia.nocookie.net/valorant/images/0/04/ControllerClassSymbol.png"
    } else if (str === "Duelist") {
        return "https://static.wikia.nocookie.net/valorant/images/f/fd/DuelistClassSymbol.png"
    } else if (str === "Initiator") {
        return "https://static.wikia.nocookie.net/valorant/images/7/77/InitiatorClassSymbol.png"
    } else if (str === "Sentinel") {
        return "https://static.wikia.nocookie.net/valorant/images/4/43/SentinelClassSymbol.png"
    } else if (str === "controller") {
        return "https://static.wikia.nocookie.net/valorant/images/0/04/ControllerClassSymbol.png"
    } else if (str === "duelist") {
        return "https://static.wikia.nocookie.net/valorant/images/f/fd/DuelistClassSymbol.png"
    } else if (str === "initiator") {
        return "https://static.wikia.nocookie.net/valorant/images/7/77/InitiatorClassSymbol.png"
    } else if (str === "sentinel") {
        return "https://static.wikia.nocookie.net/valorant/images/4/43/SentinelClassSymbol.png"
    } else if (str === "flex") {
        return "https://i.imgur.com/RErRLH0.png"
    } else if (str === "bench") {
        return "https://img.icons8.com/plasticine/344/valorant.png"
    }

    else if (str === "South Korea") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/Flag_of_South_Korea.svg/120px-Flag_of_South_Korea.svg.png"
    } else if (str === "Thailand") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_Thailand.svg/120px-Flag_of_Thailand.svg.png"
    } else if (str === "Brazil") {
        return "https://upload.wikimedia.org/wikipedia/en/thumb/0/05/Flag_of_Brazil.svg/120px-Flag_of_Brazil.svg.png"
    } else if (str === "Japan") {
        return "https://upload.wikimedia.org/wikipedia/en/thumb/9/9e/Flag_of_Japan.svg/120px-Flag_of_Japan.svg.png"
    } else if (str === "United Kingdom") {
        return "https://upload.wikimedia.org/wikipedia/en/thumb/a/ae/Flag_of_the_United_Kingdom.svg/120px-Flag_of_the_United_Kingdom.svg.png"
    } else if (str === "Croatia") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Flag_of_Croatia.svg/120px-Flag_of_Croatia.svg.png"
    } else if (str === "Czech Republic") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_Czech_Republic.svg/120px-Flag_of_the_Czech_Republic.svg.png"
    } else if (str === "Finland") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Finland.svg/120px-Flag_of_Finland.svg.png"
    } else if (str === "Ukraine") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/4/49/Flag_of_Ukraine.svg/120px-Flag_of_Ukraine.svg.png"
    } else if (str === "Turkey") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Flag_of_Turkey.svg/120px-Flag_of_Turkey.svg.png"
    } else if (str === "Poland") {
        return "https://upload.wikimedia.org/wikipedia/en/thumb/1/12/Flag_of_Poland.svg/120px-Flag_of_Poland.svg.png"
    } else if (str === "Philippines") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Flag_of_the_Philippines.svg/120px-Flag_of_the_Philippines.svg.png"
    } else if (str === "Russia") {
        return "https://upload.wikimedia.org/wikipedia/en/thumb/f/f3/Flag_of_Russia.svg/120px-Flag_of_Russia.svg.png"
    } else if (str === "Canada") {
        return "https://upload.wikimedia.org/wikipedia/en/thumb/c/cf/Flag_of_Canada.svg/120px-Flag_of_Canada.svg.png"
    } else if (str === "United States") {
        return "https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/120px-Flag_of_the_United_States.svg.png"
    } else if (str === "Belgium") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Flag_of_Belgium.svg/120px-Flag_of_Belgium.svg.png"
    } else if (str === "Argentina") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/1/1a/Flag_of_Argentina.svg/120px-Flag_of_Argentina.svg.png"
    } else if (str === "Chile") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Flag_of_Chile.svg/120px-Flag_of_Chile.svg.png"
    } else if (str === "Mongolia") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Flag_of_Mongolia.svg/120px-Flag_of_Mongolia.svg.png"
    } else if (str === "Singapore") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/4/48/Flag_of_Singapore.svg/1280px-Flag_of_Singapore.svg.png"
    } else if (str === "Indonesia") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Flag_of_Indonesia.svg/120px-Flag_of_Indonesia.svg.png"
    } else if (str === "Malaysia") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Flag_of_Malaysia.svg/120px-Flag_of_Malaysia.svg.png"
    } else if (str === "Lithuania") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Flag_of_Lithuania.svg/120px-Flag_of_Lithuania.svg.png"
    } else if (str === "Spain") {
        return "https://upload.wikimedia.org/wikipedia/en/thumb/9/9a/Flag_of_Spain.svg/120px-Flag_of_Spain.svg.png"
    } else if (str === "Denmark") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Flag_of_Denmark.svg/120px-Flag_of_Denmark.svg.png"
    } else if (str === "Sweden") {
        return "https://upload.wikimedia.org/wikipedia/en/thumb/4/4c/Flag_of_Sweden.svg/120px-Flag_of_Sweden.svg.png"
    } else if (str === "France") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_France_%281794%E2%80%931815%2C_1830%E2%80%931974%2C_2020%E2%80%93present%29.svg/120px-Flag_of_France_%281794%E2%80%931815%2C_1830%E2%80%931974%2C_2020%E2%80%93present%29.svg.png"
    } else if (str === "Lebanon") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Flag_of_Lebanon.svg/120px-Flag_of_Lebanon.svg.png"
    } else if (str === "Morocco") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2c/Flag_of_Morocco.svg/120px-Flag_of_Morocco.svg.png"
    } else if (str === "Mexico") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fc/Flag_of_Mexico.svg/120px-Flag_of_Mexico.svg.png"
    } else if (str === "Germany") {
        return "https://upload.wikimedia.org/wikipedia/en/thumb/b/ba/Flag_of_Germany.svg/120px-Flag_of_Germany.svg.png"
    } else if (str === "Norway") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Flag_of_Norway.svg/120px-Flag_of_Norway.svg.png"
    } else if (str === "Wales") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/d/dc/Flag_of_Wales.svg/255px-Flag_of_Wales.svg.png"
    } else if (str === "Belarus") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Flag_of_Belarus.svg/120px-Flag_of_Belarus.svg.png"
    } else if (str === "Latvia") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Latvia.svg/120px-Flag_of_Latvia.svg.png"
    } else if (str === "Kazakhstan") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d3/Flag_of_Kazakhstan.svg/120px-Flag_of_Kazakhstan.svg.png"
    } else if (str === "China") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Flag_of_the_People%27s_Republic_of_China.svg/120px-Flag_of_the_People%27s_Republic_of_China.svg.png"
    } else if (str === "International") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Flag_of_the_United_Nations.svg/225px-Flag_of_the_United_Nations.svg.png"
    } else if (str === "Taiwan") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/7/72/Flag_of_the_Republic_of_China.svg/120px-Flag_of_the_Republic_of_China.svg.png"
    } else if (str === "Cambodia") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_Cambodia.svg/120px-Flag_of_Cambodia.svg.png"
    } else if (str === "Colombia") {
        return "https://upload.wikimedia.org/wikipedia/commons/thumb/2/21/Flag_of_Colombia.svg/120px-Flag_of_Colombia.svg.png"
    }
    
    else {
        return "https://wallpaperaccess.com/full/4547519.jpg"
    }
}

export default function SearchPlayers(props) {
    const [playerList, setPlayerList] = useState([])
    const [teamList, setTeamList] = useState([{ team_name: "", team_players: [] }])
    const [filterByTeam, setFilterByTeam] = useState(true)
    const [rolesList, setRoleList] = useState([{ role: "", role_players: [] }])
    const [filterByRole, setFilterByRole] = useState(false)
    const [priceDescendingList, setPriceDescendingList] = useState([])
    const [sortPriceDescending, setSortPriceDescending] = useState(false)
    const [priceAscendingList, setPriceAscendingList] = useState([])
    const [sortPriceAscending, setSortPriceAscending] = useState(false)
    const [userRecruits, setUserRecruits] = useState([])

    const [budget, setBudget] = useState(14000)
    const [duelist1, setDuelist1] = useState({})
    const [duelist2, setDuelist2] = useState({})
    const [initiator1, setInitiator1] = useState({})
    const [initiator2, setInitiator2] = useState({})
    const [sentinel1, setSentinel1] = useState({})
    const [sentinel2, setSentinel2] = useState({})
    const [controller1, setController1] = useState({})
    const [controller2, setController2] = useState({})
    const [recruitToAdd, setRecruitToAdd] = useState({}) // will be replaced with an instance of playerObj
    const [showModal, setShowModal] = useState(true) //render when the page first opens

    useEffect(() => {
        // async call to getplayerList
        var list = []
        async function fetchData() {
            list = await getPlayerList()
            if (list) {
                setPlayerList(list)
                updateSearchResults(list)
                //console.log(JSON.stringify(list)) 
                //used to get the json string used to hardcode list
            }
        }
        fetchData()

        setPlayerList(list)
        updateSearchResults(list)
    }, []);

    useEffect(() => {
        setBudget(budget)
    }, [budget]);

    function updateSearchResults(list) {
        const bar = document.getElementById('search-input')
        const search = bar !== null ? bar.value.toLowerCase() : null

        //if the search results are empty, display everything
        var newSearchResults
        if (!search || search.length === 0) {
            newSearchResults = list
        } else {
            newSearchResults = list.filter(player => player.player_name.toLowerCase().includes(search))
        }

        //update the filter by team list
        var tempTeamList = []
        for (let i = 0; i < newSearchResults.length; i++) {
            if (newSearchResults[i]) {
                if (newSearchResults[i].team_name && tempTeamList.filter(el => el.team_name.includes(newSearchResults[i].team_name)).length === 0) { //check if teamName has alr been added for list
                    var teamObj = { team_name: newSearchResults[i].team_name, team_players: newSearchResults.filter(el => el.team_name.includes(newSearchResults[i].team_name)) }
                    tempTeamList.push(teamObj)
                }
            }
        }
        setTeamList(tempTeamList)

        //update the filter by role list
        var tempRoleList = []
        for (let i = 0; i < newSearchResults.length; i++) {
            if (newSearchResults[i].roles) {
                for (let j = 0; j < newSearchResults[i].roles.length; j++) {
                    let currRole = newSearchResults[i].roles[j]
                    if (tempRoleList.filter(el => el.role.includes(currRole)).length === 0) { //check if role has alr been added for list
                        var roleObj = { role: currRole, role_players: newSearchResults.filter(el => el.roles.includes(currRole)) }
                        tempRoleList.push(roleObj)
                    }
                }
            }
        }
        setRoleList(tempRoleList)

        //update the sort by price high to low list
        var tempSortByPriceDescendingList = newSearchResults.slice()
        tempSortByPriceDescendingList.sort((a, b) => a.price - b.price); //sorts by descending order
        setPriceAscendingList(tempSortByPriceDescendingList)

        //update the sort by price low to high list
        var tempSortByPriceAscendingList = newSearchResults.slice()
        tempSortByPriceAscendingList.sort((a, b) => b.price - a.price); //sorts by ascending order
        setPriceDescendingList(tempSortByPriceAscendingList)
    }

    function updateFilterByRole() {
        setFilterByRole(true)
        setFilterByTeam(false)
        setSortPriceDescending(false)
        setSortPriceAscending(false)
    }

    function updateFilterByTeam() {
        setFilterByRole(false)
        setFilterByTeam(true)
        setSortPriceDescending(false)
        setSortPriceAscending(false)
    }

    function updateSortPriceDescending() {
        setFilterByRole(false)
        setFilterByTeam(false)
        setSortPriceDescending(true)
        setSortPriceAscending(false)
    }

    function updateSortPriceAscending() {
        setFilterByRole(false)
        setFilterByTeam(false)
        setSortPriceDescending(false)
        setSortPriceAscending(true)
    }

    function showAddOption(playerObj) {
        if (!userRecruits.includes(playerObj)) {
            setRecruitToAdd(playerObj)
            updateSearchResults(playerList) //super hacky way update the recruit list changes
        } else {
            alert("Error: You already have this player on your team.")
        }
    }



    function addRecruit(player, role) {
        if (!userRecruits.includes(player)) {
            var newBudget = budget - player.price
            if (newBudget >= 0) {
                //add player to recruits list
                var updatedRecruits = userRecruits
                updatedRecruits.push(player)
                var index = -1;

                //now update the right role, remove the old player
                if (role === "duelist1") {
                    if (duelist1.price) {
                        newBudget += parseInt(duelist1.price)
                        index = updatedRecruits.indexOf(duelist1)
                    }
                    setDuelist1(player)
                } else if (role === "duelist2") {
                    if (duelist2.price) {
                        newBudget += parseInt(duelist2.price)
                        index = updatedRecruits.indexOf(duelist2)
                    }
                    setDuelist2(player)
                } else if (role === "initiator1") {
                    if (initiator1.price) {
                        newBudget += parseInt(initiator1.price)
                        index = updatedRecruits.indexOf(initiator1)
                    }
                    setInitiator1(player)
                } else if (role === "initiator2") {
                    if (initiator2.price) {
                        newBudget += parseInt(initiator2.price)
                        index = updatedRecruits.indexOf(initiator2)
                    }
                    setInitiator2(player)
                } else if (role === "sentinel1") {
                    if (sentinel1.price) {
                        newBudget += parseInt(sentinel1.price)
                        index = updatedRecruits.indexOf(sentinel1)
                    }
                    setSentinel1(player)
                } else if (role === "sentinel2") {
                    if (sentinel2.price) {
                        newBudget += parseInt(sentinel2.price)
                        index = updatedRecruits.indexOf(sentinel2)
                    }
                    setSentinel2(player)
                } else if (role === "controller1") {
                    if (controller1.price) {
                        newBudget += parseInt(controller1.price)
                        index = updatedRecruits.indexOf(controller1)
                    }
                    setController1(player)
                } else if (role === "controller2") {
                    if (controller2.price) {
                        newBudget += parseInt(controller2.price)
                        index = updatedRecruits.indexOf(controller2)
                    }
                    setController2(player)
                }

                if (index > -1) { //remove the old player if there was one
                    updatedRecruits.splice(index, 1)
                }

                setUserRecruits(updatedRecruits)
                setBudget(newBudget)
                updateSearchResults(playerList) //super hacky way update the recruit list changes
            } else {
                alert("Error: Budget exceeded.")
            }
        } else {
            alert("Error: You already have this player on your team.")
        }
        setRecruitToAdd({}) //reset recruit to add to none
    }


    function removeRecruit(player) {
        var newBudget = budget + parseInt(player.price)

        //remove player from userRecruits list
        var updatedRecruits = userRecruits
        const index = updatedRecruits.indexOf(player)
        updatedRecruits.splice(index, 1)

        //now remove the player
        if (player === duelist1) {
            setDuelist1({})
        } else if (player === duelist2) {
            setDuelist2({})
        } else if (player === initiator1) {
            setInitiator1({})
        } else if (player === initiator2) {
            setInitiator2({})
        } else if (player === sentinel1) {
            setSentinel1({})
        } else if (player === sentinel2) {
            setSentinel2({})
        } else if (player === controller1) {
            setController1({})
        } else if (player === controller2) {
            setController2({})
        }
        setUserRecruits(updatedRecruits)
        setBudget(newBudget)
        updateSearchResults(playerList) //super hacky way update the recruit list changes
    }

    function submitTeam() {
        //check if all the roles are filled
        const form = document.getElementById('team-name-input')
        const input = form !== null ? form.value : null
        if (input) {
            //check if team name already exists
            async function checkIfTeamNameIsDuplicate() {
                const isDuplicate = await doesTeamNameExist(input)
                if (isDuplicate) {
                    alert("Error: Team name already used, please enter a new one.")
                } else {
                    //submit team name and team
                    if (userRecruits.length < 8) {
                        alert("Error: Team incomplete, try submitting again after all roles have been assigned a player.")
                    } else {
                        //can successfully submit
                        var team = {
                            controller_1: { team: controller1.team_name, name: controller1.player_name },
                            controller_2: { team: controller2.team_name, name: controller2.player_name },
                            duelist_1: { team: duelist1.team_name, name: duelist1.player_name },
                            duelist_2: { team: duelist2.team_name, name: duelist2.player_name },
                            initiator_1: { team: initiator1.team_name, name: initiator1.player_name },
                            initiator_2: { team: initiator2.team_name, name: initiator2.player_name },
                            sentinel_1: { team: sentinel1.team_name, name: sentinel1.player_name },
                            sentinel_2: { team: sentinel2.team_name, name: sentinel2.player_name },
                        }
                        props.createNewUser(input, team, budget)
                        updatePicked(controller1.player_name)
                        updatePicked(controller2.player_name)
                        updatePicked(duelist1.player_name)
                        updatePicked(duelist2.player_name)
                        updatePicked(initiator1.player_name)
                        updatePicked(initiator2.player_name)
                        updatePicked(sentinel1.player_name)
                        updatePicked(sentinel2.player_name)

                    }
                }
            }
            checkIfTeamNameIsDuplicate()
        } else {
            alert("Error: Please enter a valid team name")
        }
    }

    return (
        <div style={{ backgroundColor: "#0e1822", color: "white", backgroundSize: "cover", minHeight: "100vh", height: "100%", width: "100vw" }}>
            <div style={{ position: "fixed", zIndex: 20, width: "100%", backgroundColor: "#0e1822", }}>
                <Banner />
            </div>
            <div class="row" style={{ padding: 50, paddingTop: 88 }}>
                {/* Player Team Selection */}
                <div class="col" style={{ position: "fixed", maxHeight: "80vh", maxWidth: "35%", backgroundColor: "#32383e", padding: 15, paddingLeft: 20, borderRadius: 10, overflowY: "auto" }}>
                    <h4>Create your team</h4>
                    <form>
                        <div class="form-row">
                            {/* <div class="col-3">
                            <h6>Team name: </h6>
                        </div> */}
                            <div class="col-10">
                                <div class="row">{/* Modal for instructions / rules */}
                                    <div class="col-10">
                                        <input id={"team-name-input"} type="text" class="form-control" placeholder="Enter your team name here" />
                                    </div>
                                    <div class="col-2">
                                        <Modal show={showModal} onClick={() => setShowModal(false)}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Rules</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                                <h3 style={{ textAlign: "center" }}>Welcome to Fantasy VCT</h3>
                                                <br />
                                                <p> <em>
                                                    *Please read in its entirety*
                                                </em> </p>

                                                <h5> <strong>The Fantasy VCT format: </strong></h5>
                                                <p>
                                                    - You have the ability to choose a custom starting lineup of 5 players 
                                                    (Duelist, Initiator, Controller, Sentinel, and Flex).
                                                    <br />
                                                    -The unselected 3 players will be placed on the bench and will not earn points for that match day.
                                                    <br />
                                                    -Each position can be filled by a player with that role listed as you choose your initial team.
                                                    The Flex role can be filled by anyone.
                                                    <br />
                                                    -You will also choose a team captain for each separate match day lineup who will earn 1.5x points for that match.
                                                    <br/>
                                                </p>
                                                <br />
                                                <p>
                                                    -The Group Stage is cumulative and averaged across all group matches, meaning the lineup you choose will the lineup for all of groups.
                                                    Starting with the playoffs, each match a team plays will be separate match days.
                                                    <br />
                                                    -Every lineup will be separate from each other, meaning you can make changes to your team however you'd like.
                                                    <br />
                                                    -You can make changes to each lineup until the timer at the bottom of the page expires, indicating the start of that selected match day.
                                                    <br />
                                                    -From that point on, that specific match day lineup is locked in. 
                                                    <br />
                                                    -Editing your team is straightforward: for each player you select, you are able to "Swap" with players who are eligible. 
                                                    Note that both players in question must be able to fit in the other player's role.
                                                </p>
                                                <br />
                                                <p>
                                                    -The "Total" tab will represent how many points each player has earned for you specifically.
                                                    This means both missing points from benched players as well as additional points from captained players will be reflected.
                                                    <br />
                                                    -This is also the case when you view other teams on the "Leaderboard" page. 
                                                    The "Players" page will show the raw total points each player has earned in the tournament.

                                                </p>
                                                <h5> <strong>How the team selection works: </strong></h5>
                                                <p>
                                                    - You have a salary budget of 14000 credits to recruit your players.<br />
                                                    - You must recruit 2 players for each role (Duelist, Initiator, Sentinel, Controller).<br />
                                                    - The roles each player can be recruited as is listed.<br />
                                                </p>
                                                <h5><strong>How the fantasy points work: </strong></h5>
                                                <p>
                                                    - Players earn points through combat stats (K/D/A, FK/FD, ADR) and round stats (Rounds won, Rounds lost).
                                                    <br />
                                                    - The points tally for a match day is an average of points earned per map played.
                                                    <br />
                                                    - Points Calculation: <br />
                                                    <em>
                                                    [+10 Points per kill, +5 Points per assists, +10 Points per First Kill, +ADR/10] <br />
                                                    [-5 Points per death, -10 Points per First Death] <br />
                                                    [+5 Points per Round win, -5 Points per Round loss] <br />
                                                    <br />
                                                    </em>
                                                </p>
                                                <br />
                                                <br />
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <br />
                    <div key={"duelist"}>
                        <div class="row">
                            <div class="col-1">
                                <Image style={{ width: 30, height: 30 }} src={"https://static.wikia.nocookie.net/valorant/images/f/fd/DuelistClassSymbol.png"} />
                            </div>
                            <div class="col-11">
                                <h6 style={{ fontSize: 25 }}><b>Duelists</b></h6>
                            </div>
                        </div>
                        {
                            <div key={"duelist1-row"} class="row" style={{ paddingBottom: 10 }}>
                                <div class="col">
                                    <h6 style={{ padding: 0, margin: 0 }} key={"duelist1-info"}>{duelist1.player_name ? duelist1.player_name : "-"}</h6>
                                    {duelist1.price ?
                                        <small>Price: {duelist1.price}</small>
                                        :
                                        <></>}
                                </div>
                                <div class="col">
                                    {duelist1.player_name ?
                                        (
                                            recruitToAdd.player_name && recruitToAdd.roles.includes("duelist") ?
                                                <Button variant="success" onClick={() => addRecruit(recruitToAdd, "duelist1")}>Add</Button>
                                                :
                                                <Button variant="outline-danger" onClick={() => removeRecruit(duelist1)}>Remove</Button>
                                        )
                                        :
                                        (
                                            recruitToAdd.player_name ?
                                                (
                                                    recruitToAdd.roles.includes("duelist") ?
                                                        <Button variant="success" onClick={() => addRecruit(recruitToAdd, "duelist1")}>Add</Button>
                                                        :
                                                        <></>
                                                )
                                                :
                                                <></>
                                        )
                                    }

                                </div>
                            </div>
                        }
                        {
                            <div key={"duelist2-row"} class="row" style={{ paddingBottom: 10 }}>
                                <div class="col">
                                    <h6 style={{ padding: 0, margin: 0 }} key={"duelist2-info"}>{duelist2.player_name ? duelist2.player_name : "-"}</h6>
                                    {duelist2.price ?
                                        <small>Price: {duelist2.price}</small>
                                        :
                                        <></>}
                                </div>
                                <div class="col">
                                    {duelist2.player_name ?
                                        (
                                            recruitToAdd.player_name && recruitToAdd.roles.includes("duelist") ?
                                                <Button variant="success" onClick={() => addRecruit(recruitToAdd, "duelist2")}>Add</Button>
                                                :
                                                <Button variant="outline-danger" onClick={() => removeRecruit(duelist2)}>Remove</Button>
                                        )
                                        :
                                        (
                                            recruitToAdd.player_name ?
                                                (
                                                    recruitToAdd.roles.includes("duelist") ?
                                                        <Button variant="success" onClick={() => addRecruit(recruitToAdd, "duelist2")}>Add</Button>
                                                        :
                                                        <></>
                                                )
                                                :
                                                <></>
                                        )
                                    }

                                </div>
                            </div>
                        }
                    </div>
                    <div key={"initiator"}>
                        <div class="row">
                            <div class="col-1">
                                <Image style={{ width: 30, height: 30 }} src={"https://static.wikia.nocookie.net/valorant/images/7/77/InitiatorClassSymbol.png"} />
                            </div>
                            <div class="col-11">
                                <h6 style={{ fontSize: 25 }}><b>Initiators</b></h6>
                            </div>
                        </div>
                        {
                            <div key={"initiator1-row"} class="row" style={{ paddingBottom: 10 }}>
                                <div class="col">
                                    <h6 style={{ padding: 0, margin: 0 }} key={"initiator1-info"}>{initiator1.player_name ? initiator1.player_name : "-"}</h6>
                                    {initiator1.price ?
                                        <small>Price: {initiator1.price}</small>
                                        :
                                        <></>}
                                </div>
                                <div class="col">
                                    {initiator1.player_name ?
                                        (
                                            recruitToAdd.player_name && recruitToAdd.roles.includes("initiator") ?
                                                <Button variant="success" onClick={() => addRecruit(recruitToAdd, "initiator1")}>Add</Button>
                                                :
                                                <Button variant="outline-danger" onClick={() => removeRecruit(initiator1)}>Remove</Button>
                                        )
                                        :
                                        (
                                            recruitToAdd.player_name ?
                                                (
                                                    recruitToAdd.roles.includes("initiator") ?
                                                        <Button variant="success" onClick={() => addRecruit(recruitToAdd, "initiator1")}>Add</Button>
                                                        :
                                                        <></>
                                                )
                                                :
                                                <></>
                                        )
                                    }

                                </div>
                            </div>
                        }
                        {
                            <div key={"initiator2-row"} class="row" style={{ paddingBottom: 10 }}>
                                <div class="col">
                                    <h6 style={{ padding: 0, margin: 0 }} key={"initiator2-info"}>{initiator2.player_name ? initiator2.player_name : "-"}</h6>
                                    {initiator2.price ?
                                        <small>Price: {initiator2.price}</small>
                                        :
                                        <></>}
                                </div>
                                <div class="col">
                                    {initiator2.player_name ?
                                        (
                                            recruitToAdd.player_name && recruitToAdd.roles.includes("initiator") ?
                                                <Button variant="success" onClick={() => addRecruit(recruitToAdd, "initiator2")}>Add</Button>
                                                :
                                                <Button variant="outline-danger" onClick={() => removeRecruit(initiator2)}>Remove</Button>
                                        )
                                        :
                                        (
                                            recruitToAdd.player_name ?
                                                (
                                                    recruitToAdd.roles.includes("initiator") ?
                                                        <Button variant="success" onClick={() => addRecruit(recruitToAdd, "initiator2")}>Add</Button>
                                                        :
                                                        <></>
                                                )
                                                :
                                                <></>
                                        )
                                    }

                                </div>
                            </div>
                        }
                    </div>
                    <div key={"sentinel"}>
                        <div class="row">
                            <div class="col-1">
                                <Image style={{ width: 30, height: 30 }} src={"https://static.wikia.nocookie.net/valorant/images/4/43/SentinelClassSymbol.png"} />
                            </div>
                            <div class="col-11">
                                <h6 style={{ fontSize: 25 }}><b>Sentinels</b></h6>
                            </div>
                        </div>
                        {
                            <div key={"sentinel1-row"} class="row" style={{ paddingBottom: 10 }}>
                                <div class="col">
                                    <h6 style={{ padding: 0, margin: 0 }} key={"sentinel1-info"}>{sentinel1.player_name ? sentinel1.player_name : "-"}</h6>
                                    {sentinel1.price ?
                                        <small>Price: {sentinel1.price}</small>
                                        :
                                        <></>}
                                </div>
                                <div class="col">
                                    {sentinel1.player_name ?
                                        (
                                            recruitToAdd.player_name && recruitToAdd.roles.includes("sentinel") ?
                                                <Button variant="success" onClick={() => addRecruit(recruitToAdd, "sentinel1")}>Add</Button>
                                                :
                                                <Button variant="outline-danger" onClick={() => removeRecruit(sentinel1)}>Remove</Button>
                                        )
                                        :
                                        (
                                            recruitToAdd.player_name ?
                                                (
                                                    recruitToAdd.roles.includes("sentinel") ?
                                                        <Button variant="success" onClick={() => addRecruit(recruitToAdd, "sentinel1")}>Add</Button>
                                                        :
                                                        <></>
                                                )
                                                :
                                                <></>
                                        )
                                    }

                                </div>
                            </div>
                        }
                        {
                            <div key={"sentinel2-row"} class="row" style={{ paddingBottom: 10 }}>
                                <div class="col">
                                    <h6 style={{ padding: 0, margin: 0 }} key={"sentinel2-info"}>{sentinel2.player_name ? sentinel2.player_name : "-"}</h6>
                                    {sentinel2.price ?
                                        <small>Price: {sentinel2.price}</small>
                                        :
                                        <></>}
                                </div>
                                <div class="col">
                                    {sentinel2.player_name ?
                                        (
                                            recruitToAdd.player_name && recruitToAdd.roles.includes("sentinel") ?
                                                <Button variant="success" onClick={() => addRecruit(recruitToAdd, "sentinel2")}>Add</Button>
                                                :
                                                <Button variant="outline-danger" onClick={() => removeRecruit(sentinel2)}>Remove</Button>
                                        )
                                        :
                                        (
                                            recruitToAdd.player_name ?
                                                (
                                                    recruitToAdd.roles.includes("sentinel") ?
                                                        <Button variant="success" onClick={() => addRecruit(recruitToAdd, "sentinel2")}>Add</Button>
                                                        :
                                                        <></>
                                                )
                                                :
                                                <></>
                                        )
                                    }

                                </div>
                            </div>
                        }
                    </div>
                    <div key={"controller"}>
                        <div class="row">
                            <div class="col-1">
                                <Image style={{ width: 30, height: 30 }} src={"https://static.wikia.nocookie.net/valorant/images/0/04/ControllerClassSymbol.png"} />
                            </div>
                            <div class="col-11">
                                <h6 style={{ fontSize: 25 }}><b>Controllers</b></h6>
                            </div>
                        </div>
                        {
                            <div key={"controller1-row"} class="row" style={{ paddingBottom: 10 }}>
                                <div class="col">
                                    <h6 style={{ padding: 0, margin: 0 }} key={"controller1-info"}>{controller1.player_name ? controller1.player_name : "-"}</h6>
                                    {controller1.price ?
                                        <small>Price: {controller1.price}</small>
                                        :
                                        <></>}
                                </div>
                                <div class="col">
                                    {controller1.player_name ?
                                        (
                                            recruitToAdd.player_name && recruitToAdd.roles.includes("controller") ?
                                                <Button variant="success" onClick={() => addRecruit(recruitToAdd, "controller1")}>Add</Button>
                                                :
                                                <Button variant="outline-danger" onClick={() => removeRecruit(controller1)}>Remove</Button>
                                        )
                                        :
                                        (
                                            recruitToAdd.player_name ?
                                                (
                                                    recruitToAdd.roles.includes("controller") ?
                                                        <Button variant="success" onClick={() => addRecruit(recruitToAdd, "controller1")}>Add</Button>
                                                        :
                                                        <></>
                                                )
                                                :
                                                <></>
                                        )
                                    }

                                </div>
                            </div>
                        }
                        {
                            <div key={"controller2-row"} class="row" style={{ paddingBottom: 10 }}>
                                <div class="col">
                                    <h6 style={{ padding: 0, margin: 0 }} key={"controller2-info"}>{controller2.player_name ? controller2.player_name : "-"}</h6>
                                    {controller2.price ?
                                        <small>Price: {controller2.price}</small>
                                        :
                                        <></>}
                                </div>
                                <div class="col">
                                    {controller2.player_name ?
                                        (
                                            recruitToAdd.player_name && recruitToAdd.roles.includes("controller") ?
                                                <Button variant="success" onClick={() => addRecruit(recruitToAdd, "controller2")}>Add</Button>
                                                :
                                                <Button variant="outline-danger" onClick={() => removeRecruit(controller2)}>Remove</Button>
                                        )
                                        :
                                        (
                                            recruitToAdd.player_name ?
                                                (
                                                    recruitToAdd.roles.includes("controller") ?
                                                        <Button variant="success" onClick={() => addRecruit(recruitToAdd, "controller2")}>Add</Button>
                                                        :
                                                        <></>
                                                )
                                                :
                                                <></>
                                        )
                                    }

                                </div>
                            </div>
                        }
                    </div>
                    {/* Submit team section */}
                    <div style={{ paddingTop: 20 }}>
                        <h6>Remaining budget: {budget}</h6>
                        <Button variant="primary" onClick={() => submitTeam()}>Submit Team</Button>
                    </div>
                </div >
                {/* Search, filter, and sort section */}
                < div class="col-8" style={{ position: "relative", left: "38%", width: "85%" }
                }>
                    <div style={{ position: "fixed", paddingBottom: 20, width: "60%", backgroundColor: "#0e1822", zIndex: 15 }}>
                        <h3 style={{ paddingBottom: 10 }}>Search for Players</h3>
                        <div key={"search-bar"} class="input-group rounded">
                            <input id="search-input" type="search" class="form-control rounded" placeholder="Type to search by player name..." aria-label="Search"
                                aria-describedby="search-addon" onChange={() => updateSearchResults(playerList)} />
                            <span class="input-group-text border-0" id="search-addon">
                                <i class="fas fa-search"></i>
                            </span>
                        </div>
                        <div key={"radio-button-team-filter"} class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" onClick={() => updateFilterByTeam()} />
                            <label class="form-check-label">Filter by team (default)</label>
                        </div>
                        <div key={"radio-button-role-filter"} class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" onClick={() => updateFilterByRole()} />
                            <label class="form-check-label">Filter by role</label>
                        </div>
                        <div key={"radio-button-sort-price-ascending"} class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" onClick={() => updateSortPriceAscending()} />
                            <label class="form-check-label">Sort by price ascending</label>
                        </div>
                        <div key={"radio-button-sort-price-descending"} class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4" onClick={() => updateSortPriceDescending()} />
                            <label class="form-check-label">Sort by price descending</label>
                        </div>
                    </div>
                    {/* Search display results */}
                    <div style={{ paddingTop: 155 }}>
                        {/* Block for displaying based on filter by team */}
                        {filterByTeam &&
                            teamList.map((el) => (
                                <div key={el.team_name + "-display-block"} style={{ paddingBottom: 25 }}>
                                    <h3 key={el.team_name + "-display-result"}>
                                        <Image style={{ width: 50, height: 50 }} src={getImageLink(el.team_name)} />
                                        {"   " + el.team_name}
                                    </h3>
                                    <PlayerCardDisplayBlock key={el.team_name + "-players-display-result"} playerList={el.team_players} showAddOption={showAddOption} />
                                </div>
                            ))
                        }
                        {/* Block for displaying based on filter by role */}
                        {filterByRole &&
                            rolesList.map((el) => (
                                <div key={el.role + "-display-block"} style={{ paddingBottom: 25 }}>
                                    <h3 key={el.role + "-display-result"}>
                                        <Image style={{ width: 40, height: 40 }} src={getImageLink(el.role.charAt(0).toUpperCase() + el.role.slice(1))} />
                                        {"   " + el.role.charAt(0).toUpperCase() + el.role.slice(1)}
                                    </h3>
                                    <PlayerCardDisplayBlock key={el.role + "-players-display-result"} playerList={el.role_players} showAddOption={showAddOption} />
                                </div>
                            ))
                        }
                        {/* Block for displaying based on sort by price ascending */}
                        {sortPriceAscending &&

                            <PlayerCardDisplayBlock key={"ascending-price-players-display-result"} playerList={priceAscendingList} showAddOption={showAddOption} />
                        }
                        {/* Block for displaying based on sort by price descending */}
                        {sortPriceDescending &&
                            <PlayerCardDisplayBlock key={"descending-price-players-display-result"} playerList={priceDescendingList} showAddOption={showAddOption} />
                        }
                    </div>
                </div >
            </div >
        </div >
    );

}