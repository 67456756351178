import React, { useEffect, useState, useContext } from "react";
import { getUserList, getUserByUID } from "../components/Firebase";
import { UserContext } from "../provider/UserProvider";
import Banner from "../components/Banner";
// import Card from "react-bootstrap/Card"
import { Table } from "react-bootstrap";
import TeamPopUp from "../components/TeamPopUp";

export default function Leaderboard(props) {
  const [userList, setUserList] = useState([]);
  const [userName, setUserName] = useState("");

  const user = useContext(UserContext);


  useEffect(() => {
    async function fetchData() {
      var list = await getUserList();
      const name = await getUserByUID(user);
      list.sort(function (a, b) {
        return b.points - a.points;
      });
      setUserList(list);
      setUserName(name);
    }
    fetchData();
  }, [user]);

  var board = userList.map((user) => {
    return user.display_team_name === userName ? (
      <tr style={{ color: "#FFD700"}}>
        <td>{userList.indexOf(user) + 1}</td>
        <td>{user.display_team_name}</td>
        <td>{user.points}</td>
      </tr>
    ) : (
      <tr>
        <td>{userList.indexOf(user) + 1}</td>
        <TeamPopUp user={user} userList={userList}></TeamPopUp>
        <td>{user.points}</td>
      </tr>
    );
  });

  return (
    <div
      style={{
        backgroundColor: "#0e1822",
        backgroundSize: "cover",
        minHeight: "100vh", height: "100%"
      }}
    >
      <Banner/>
      <br />
      <div style={{ paddingLeft: "15%", paddingRight: "15%" }}>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th width="50">Rank</th>
              <th>Team Name</th>
              <th>Points</th>
            </tr>
          </thead>
          <tbody>{board}</tbody>
        </Table>
      </div>
    </div>
  );
}
