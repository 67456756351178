import React, { useContext, useEffect, useState } from "react";
import PlayerRow from "./PlayerRow";
// import BenchPlayerRow from "./BenchPlayerRow";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { editLineup, getPlayer, getPlayerTeam } from "./Firebase";
import { UserContext } from "../provider/UserProvider";
import Image from "react-bootstrap/Image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getImageLink } from "./SearchPlayers";

export default function StatTable(props) {

  const user = useContext(UserContext)
  var defaultTeam

  if (props.mw === 0) {
    defaultTeam = {
      duelist_1: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      duelist_2: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      controller_1: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      controller_2: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      initiator_1: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      initiator_2: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      sentinel_1: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      sentinel_2: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 }
    }
  } else {
    defaultTeam = {
      duelist: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      controller: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      initiator: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      sentinel: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      flex: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      bench_1: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      bench_2: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      bench_3: { a: 0, d: 0, adr: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    }
  }




  const [team, setTeam] = useState(defaultTeam)
  const [tempTeam, setTempTeam] = useState(defaultTeam)

  const [totalPoints, setTotalPoints] = useState(0)
  //const [userteam, setuserteam] = useState(props.userteam)

  const [teamList, setTeamList] = useState({})
  const [tempTeamList, setTempTeamList] = useState({})

  const [editMode, setEditMode] = useState(false)
  const [selectedPos, setSelectedPos] = useState()
  const [isPlayerSelected, setIsPlayerSelected] = useState(false)
  const [selectedPlayer, setSelectedPlayer] = useState()
  const [roleList, setRoleList] = useState({})
  const [tempCaptain, setTempCaptain] = useState("")

  const [timer, setTimer] = useState("")
  const [expired, setExpired] = useState(false)

  function getRemainingTime() {
    switch (props.mw) {
      case (1):
        var endTime = new Date(Date.UTC(2023, 7, 6, 19)).getTime();
      break
      case (2):
        endTime = new Date(Date.UTC(2023, 7, 16, 19)).getTime();
      break
      case (3):
        endTime = new Date(Date.UTC(2023, 7, 18, 19)).getTime();
      break
      case (4):
        endTime = new Date(Date.UTC(2023, 7, 20, 19)).getTime();
      break
      case (5):
        endTime = new Date(Date.UTC(2023, 7, 24, 19)).getTime();
      break
      case (6):
        endTime = new Date(Date.UTC(2023, 7, 25, 19)).getTime();
      break
      case (7):
        endTime = new Date(Date.UTC(2023, 7, 26, 19)).getTime();
      break
      default: return
    }
    var now = new Date().getTime();
    var timeleft = endTime - now
    if (timeleft < 0) {
      setExpired(true)
    } else {
      setExpired(false)
    }

    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));

    var showTime = days + "d " + hours + "h " + minutes + "m"
    setTimer(showTime)
    return timeleft
  }

  setInterval(function () {
    getRemainingTime()
  }, 60000)

  useEffect(() => {
    getRemainingTime()
  })

  useEffect(() => {
    if (props.teamdata !== null) {
      const team = props.teamdata
      //console.log(team)
      setTeam(props.teamdata)
      setTempTeam(props.teamdata)
      setTempCaptain(props.teamdata.captain)
      if (props.mw === 0) {
        var pointSum = 
        team.duelist_1.points +
          team.duelist_2.points +
          team.controller_1.points +
          team.controller_2.points +
          team.initiator_1.points +
          team.initiator_2.points +
          team.sentinel_1.points +
          team.sentinel_2.points
      } else {
        pointSum = 
        team.duelist.points +
        team.controller.points +
        team.initiator.points +
        team.sentinel.points +
        team.flex.points +
        Math.round(team[team.captain].points / 2)
      }
      setTotalPoints(pointSum)
    }
  }, [props.teamdata, props.istotal, user, props.mw])

  useEffect(() => {
    if (props.userteam !== null && props.userteam !== undefined) {
      //setuserteam(props.userteam)
      if (props.mw === 0) {
        let list = {
          duelist_1: props.userteam.duelist_1.team,
          duelist_2: props.userteam.duelist_2.team,
          initiator_1: props.userteam.initiator_1.team,
          initiator_2: props.userteam.initiator_2.team,
          controller_1: props.userteam.controller_1.team,
          controller_2: props.userteam.controller_2.team,
          sentinel_1: props.userteam.sentinel_1.team, 
          sentinel_2: props.userteam.sentinel_2.team
        }
        setTeamList(list)
        setTempTeamList(list)
      } else {
        let list = {
          duelist: props.userteam["team_" + props.mw].duelist.team,
          initiator: props.userteam["team_" + props.mw].initiator.team,
          controller: props.userteam["team_" + props.mw].controller.team,
          sentinel: props.userteam["team_" + props.mw].sentinel.team,
          flex: props.userteam["team_" + props.mw].flex.team,
          bench_1: props.userteam["team_" + props.mw].bench_1.team,
          bench_2: props.userteam["team_" + props.mw].bench_2.team,
          bench_3: props.userteam["team_" + props.mw].bench_3.team
        }
        setTeamList(list)
        setTempTeamList(list)
      }

    }
  }, [props.userteam, props.mw])



  function isCaptain(pos) {
    return team.captain === pos
  }

  function isTempCaptain(pos) {
    return tempCaptain === pos
  }

  async function getPlayerRoles() {
    let list = {}
    if (props.mw !== 0) {
      for (const player in team) {
        if (player !== "captain") {
          let playerObj = await getPlayer(team[player].player_name)
          let tempList = playerObj.roles
          tempList.push("bench_1")
          tempList.push("bench_2")
          tempList.push("bench_3")
          tempList.push("flex")
          list[team[player].player_name] = tempList
        }
      }
      setRoleList(list)
    }
  }

  function playerSelected(pos) {
    setSelectedPos(pos)
    setSelectedPlayer(team[pos])
    setIsPlayerSelected(true)
  }

  function canSwapPlayer(pos) {
    // console.log(roleList[tempTeam[pos].player_name])
    // console.log(roleList[tempTeam[selectedPos].player_name])
    // if (selectedPos === undefined || selectedPos === null || roleList[tempTeam[pos].player_name] === undefined) {
    //   return false
    // } else {
    //   return selectedPos && isPlayerSelected && 
    //   selectedPos !== pos &&
    //   roleList[tempTeam[pos].player_name].includes(selectedPos) && 
    //   roleList[tempTeam[selectedPos].player_name].includes(pos)
    // }
    try {
      return selectedPos && isPlayerSelected && 
      selectedPos !== pos &&
      roleList[tempTeam[pos].player_name].includes(selectedPos) && 
      roleList[tempTeam[selectedPos].player_name].includes(pos)
    } catch (error) {
      return false
    }

  }

  function clearSelection() {
    setIsPlayerSelected(false)
    setSelectedPos(null)
    setSelectedPlayer(null)
  }

  async function swapTempPlayer(oldPlayer, newPlayer, oldPos, newPos) {
    let newPlayerTeam = await getPlayerTeam(newPlayer.player_name)
    let oldPlayerTeam = await getPlayerTeam(oldPlayer.player_name)
    
    tempTeam[oldPos] = newPlayer
    tempTeam[newPos] = oldPlayer
    let changeTeamList = tempTeamList
    changeTeamList[oldPos] = newPlayerTeam
    changeTeamList[newPos] = oldPlayerTeam
    setTempTeamList(changeTeamList)
    clearSelection()

    //await editTeam(oldPlayer, newPlayer, oldPos, newPos, teamNum, user)
  }

  async function startEdit() {
    setEditMode(true)
    await getPlayerRoles()
  }

  async function saveLineup() {
    getRemainingTime()
    setEditMode(false)
    if (expired) {
      alert("Selected Match Day has Started")
      return
    }
    await editLineup(props.mw, user, tempTeam)
    setTeamList(tempTeamList)

    var pointSum = 
    team.duelist.points +
    team.controller.points +
    team.initiator.points +
    team.sentinel.points +
    team.flex.points +
    Math.round(team[team.captain].points / 2)
    setTotalPoints(pointSum)
  }

  async function setNewCaptain(pos) {
    tempTeam.captain = pos
    setTempCaptain(pos)
    //await setCaptain(pos, user, props.mw)
  }

  return (
    <div
      style={{
        backgroundColor: "#0e1822",
        backgroundSize: "cover",
        minHeight: "150vh", height: "100%"
      }}>
        {props.istotal ? (
          <Table striped bordered hover variant="dark">
            <thead>
              <tr>
                <th width="50">Role</th>
                <th width="500">Player</th>
                <th width="100">Kills</th>
                <th width="100">Deaths</th>
                <th width="100">Assists</th>
                <th width="100">FK</th>
                <th width="100">FD</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              <PlayerRow
              imagelink="https://static.wikia.nocookie.net/valorant/images/f/fd/DuelistClassSymbol.png"
              rolename="Duelist"
              istotal={props.istotal}
              player={team.duelist_1}
              team={teamList.duelist_1}
              />
              <PlayerRow
                imagelink="https://static.wikia.nocookie.net/valorant/images/f/fd/DuelistClassSymbol.png"
                rolename="Duelist"
                istotal={props.istotal}
                player={team.duelist_2}
                team={teamList.duelist_2}
              />
              <PlayerRow
                imagelink="https://static.wikia.nocookie.net/valorant/images/7/77/InitiatorClassSymbol.png"
                rolename="Initiator"
                istotal={props.istotal}
                player={team.initiator_1}
                team={teamList.initiator_1}
              />
              <PlayerRow
                imagelink="https://static.wikia.nocookie.net/valorant/images/7/77/InitiatorClassSymbol.png"
                rolename="Initiator"
                istotal={props.istotal}
                player={team.initiator_2}
                team={teamList.initiator_2}
              />
              <PlayerRow
                imagelink="https://static.wikia.nocookie.net/valorant/images/0/04/ControllerClassSymbol.png"
                rolename="Controller"
                istotal={props.istotal}
                player={team.controller_1}
                team={teamList.controller_1}
              />
              <PlayerRow
                imagelink="https://static.wikia.nocookie.net/valorant/images/0/04/ControllerClassSymbol.png"
                rolename="Controller"
                istotal={props.istotal}
                player={team.controller_2}
                team={teamList.controller_2}
              />
              <PlayerRow
                imagelink="https://static.wikia.nocookie.net/valorant/images/4/43/SentinelClassSymbol.png"
                rolename="Sentinel"
                istotal={props.istotal}
                player={team.sentinel_1}
                team={teamList.sentinel_1}
              />
              <PlayerRow
                imagelink="https://static.wikia.nocookie.net/valorant/images/4/43/SentinelClassSymbol.png"
                rolename="Sentinel"
                istotal={props.istotal}
                player={team.sentinel_2}
                team={teamList.sentinel_2}
              />
            </tbody>
          </Table>
        ) : (
          <div>
            {!editMode ? (
              <div>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>Player</th>
                    <th>Kills</th>
                    <th>Deaths</th>
                    <th>Assists</th>
                    <th>FK</th>
                    <th>FD</th>
                    <th>ADR</th>
                    <th>Points</th>
                  </tr>
                </thead>
                <tbody>
                  <PlayerRow
                    imagelink="https://static.wikia.nocookie.net/valorant/images/f/fd/DuelistClassSymbol.png"
                    rolename="Duelist"
                    istotal={props.istotal}
                    player={team.duelist}
                    team={teamList.duelist}
                    iscaptain={isCaptain("duelist")}
                  />
                  <PlayerRow
                    imagelink="https://static.wikia.nocookie.net/valorant/images/7/77/InitiatorClassSymbol.png"
                    rolename="Initiator"
                    istotal={props.istotal}
                    player={team.initiator}
                    team={teamList.initiator}
                    iscaptain={isCaptain("initiator")}
                  />
                  <PlayerRow
                    imagelink="https://static.wikia.nocookie.net/valorant/images/0/04/ControllerClassSymbol.png"
                    rolename="Controller"
                    istotal={props.istotal}
                    player={team.controller}
                    team={teamList.controller}
                    iscaptain={isCaptain("controller")}
                  />
                  <PlayerRow
                    imagelink="https://static.wikia.nocookie.net/valorant/images/4/43/SentinelClassSymbol.png"
                    rolename="Sentinel"
                    istotal={props.istotal}
                    player={team.sentinel}
                    team={teamList.sentinel}
                    iscaptain={isCaptain("sentinel")}
                  />
                  <PlayerRow
                    imagelink="https://i.imgur.com/RErRLH0.png"
                    rolename="Flex"
                    istotal={props.istotal}
                    player={team.flex}
                    team={teamList.flex}
                    iscaptain={isCaptain("flex")}
                  />
                </tbody>
              </Table>
              <div style={{ float: "left" }}>
                <Card style={{ width: "100%" }} bg="dark" text="white" border="secondary">
                  <Card.Header as="h6">Bench</Card.Header>
                </Card>
              </div>



              <Table striped bordered hover variant="dark">
                <tbody>
                <PlayerRow
                    imagelink="https://img.icons8.com/plasticine/344/valorant.png"
                    rolename="Bench"
                    istotal={props.istotal}
                    player={team.bench_1}
                    team={teamList.bench_1}
                    iscaptain={isCaptain("bench")}
                  />
                  <PlayerRow
                    imagelink="https://img.icons8.com/plasticine/344/valorant.png"
                    rolename="Bench"
                    istotal={props.istotal}
                    player={team.bench_2}
                    team={teamList.bench_2}
                    iscaptain={isCaptain("bench")}
                  />
                  <PlayerRow
                    imagelink="https://img.icons8.com/plasticine/344/valorant.png"
                    rolename="Bench"
                    istotal={props.istotal}
                    player={team.bench_3}
                    team={teamList.bench_3}
                    iscaptain={isCaptain("bench")}
                  />

                </tbody>
              </Table>
          </div>
          ) : (
            <div>
              <Table striped bordered hover variant="dark">
                <thead>
                  <tr>
                    <th width="50">Role</th>
                    <th>Player</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width="50">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip" {...props}>
                            {"Duelist"}
                          </Tooltip>
                        }
                      >
                        <Image style={{ width: 30, height: 30 }} src={getImageLink("duelist")} />
                      </OverlayTrigger>
                    </td>
                    {isTempCaptain("duelist") ? (
                      <td>
                        <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["duelist"])}/>
                        {" " + tempTeam.duelist.player_name}
                        <Image style={{ width: 20, height: 20}} src={"https://i.imgur.com/ILor5VB.png"}/>
                      </td>
                    ) : (
                      <td>
                        <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["duelist"])}/>
                        {" " + tempTeam.duelist.player_name}
                      </td>
                    )}

                    {isTempCaptain("duelist") ? (
                      canSwapPlayer("duelist") ? (
                        <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.duelist, selectedPlayer, "duelist", selectedPos, props.mw)}>
                            Swap
                          </Button>
                        </td>
                      ) : (
                        <td width="400">
                          <Button variant="info" onClick={() => playerSelected("duelist")}>
                            Select
                          </Button>
                      </td>
                      )

                    ) : (
                      canSwapPlayer("duelist") ? (
                        <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.duelist, selectedPlayer, "duelist", selectedPos, props.mw)}>
                            Swap
                          </Button>
                          <Button variant="outline-warning" onClick={() => setNewCaptain("duelist")}>
                            Set Captain
                          </Button>
                        </td>
                      ) : (
                        <td width="400">
                        <Button variant="info" onClick={() => playerSelected("duelist")}>
                          Select
                        </Button>
                        <Button variant="outline-warning" onClick={() => setNewCaptain("duelist")}>
                          Set Captain
                        </Button>
                      </td>
                      )

                    )}

                  </tr>
                  
                  <tr>
                    <td width="50">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip" {...props}>
                            {"Initiator"}
                          </Tooltip>
                        }
                      >
                        <Image style={{ width: 30, height: 30 }} src={getImageLink("initiator")} />
                      </OverlayTrigger>
                    </td>

                    {isTempCaptain("initiator") ? (
                      <td>
                        <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["initiator"])}/>
                        {" " + tempTeam.initiator.player_name}
                        <Image style={{ width: 20, height: 20}} src={"https://i.imgur.com/ILor5VB.png"}/>
                      </td>
                    ) : (
                      <td>
                        <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["initiator"])}/>
                        {" " + tempTeam.initiator.player_name}
                      </td>
                    )}

                    {isTempCaptain("initiator") ? (
                      canSwapPlayer("initiator") ? (
                        <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.initiator, selectedPlayer, "initiator", selectedPos, props.mw)}>
                            Swap
                          </Button>
                        </td>
                      ) : (
                        <td width="400">
                          <Button variant="info" onClick={() => playerSelected("initiator")}>
                            Select
                          </Button>
                      </td>
                      )
                    ) : (
                      canSwapPlayer("initiator") ? (
                        <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.initiator, selectedPlayer, "initiator", selectedPos, props.mw)}>
                            Swap
                          </Button>
                          <Button variant="outline-warning" onClick={() => setNewCaptain("initiator")}>
                            Set Captain
                          </Button>
                        </td>
                      ) : (
                        <td width="400">
                        <Button variant="info" onClick={() => playerSelected("initiator")}>
                          Select
                        </Button>
                        <Button variant="outline-warning" onClick={() => setNewCaptain("initiator")}>
                          Set Captain
                        </Button>
                      </td>
                      )

                    )}
                  </tr>

                  <tr>
                    <td width="50">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip" {...props}>
                            {"Controller"}
                          </Tooltip>
                        }
                      >
                        <Image style={{ width: 30, height: 30 }} src={getImageLink("controller")} />
                      </OverlayTrigger>
                    </td>
                    {isTempCaptain("controller") ? (
                      <td>
                        <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["controller"])}/>
                        {" " + tempTeam.controller.player_name}
                        <Image style={{ width: 20, height: 20}} src={"https://i.imgur.com/ILor5VB.png"}/>
                      </td>
                    ) : (
                      <td>
                        <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["controller"])}/>
                        {" " + tempTeam.controller.player_name}
                      </td>
                    )}

                    {isTempCaptain("controller") ? (
                      canSwapPlayer("controller") ? (
                        <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.controller, selectedPlayer, "controller", selectedPos, props.mw)}>
                            Swap
                          </Button>
                        </td>
                      ) : (
                        <td width="400">
                          <Button variant="info" onClick={() => playerSelected("controller")}>
                            Select
                          </Button>
                      </td>
                      )

                    ) : (
                      canSwapPlayer("controller") ? (
                        <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.controller, selectedPlayer, "controller", selectedPos, props.mw)}>
                            Swap
                          </Button>
                          <Button variant="outline-warning" onClick={() => setNewCaptain("controller")}>
                            Set Captain
                          </Button>
                        </td>
                      ) : (
                        <td width="400">
                        <Button variant="info" onClick={() => playerSelected("controller")}>
                          Select
                        </Button>
                        <Button variant="outline-warning" onClick={() => setNewCaptain("controller")}>
                          Set Captain
                        </Button>
                      </td>
                      )

                    )}
                  </tr>

                  <tr>
                    <td width="50">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip" {...props}>
                            {"Sentinel"}
                          </Tooltip>
                        }
                      >
                        <Image style={{ width: 30, height: 30 }} src={getImageLink("sentinel")} />
                      </OverlayTrigger>
                    </td>
                    {isTempCaptain("sentinel") ? (
                      <td>
                        <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["sentinel"])}/>
                        {" " + tempTeam.sentinel.player_name}
                        <Image style={{ width: 20, height: 20}} src={"https://i.imgur.com/ILor5VB.png"}/>
                      </td>
                    ) : (
                      <td>
                        <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["sentinel"])}/>
                        {" " + tempTeam.sentinel.player_name}
                      </td>
                    )}
                    {isTempCaptain("sentinel") ? (
                      canSwapPlayer("sentinel") ? (
                        <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.sentinel, selectedPlayer, "sentinel", selectedPos, props.mw)}>
                            Swap
                          </Button>
                        </td>
                      ) : (
                        <td width="400">
                          <Button variant="info" onClick={() => playerSelected("sentinel")}>
                            Select
                          </Button>
                      </td>
                      )

                    ) : (
                      canSwapPlayer("sentinel") ? (
                        <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.sentinel, selectedPlayer, "sentinel", selectedPos, props.mw)}>
                            Swap
                          </Button>
                          <Button variant="outline-warning" onClick={() => setNewCaptain("sentinel")}>
                            Set Captain
                          </Button>
                        </td>
                      ) : (
                        <td width="400">
                        <Button variant="info" onClick={() => playerSelected("sentinel")}>
                          Select
                        </Button>
                        <Button variant="outline-warning" onClick={() => setNewCaptain("sentinel")}>
                          Set Captain
                        </Button>
                      </td>
                      )

                    )}
                  </tr>

                  <tr>
                    <td width="50">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip" {...props}>
                            {"Flex"}
                          </Tooltip>
                        }
                      >
                        <Image style={{ width: 30, height: 30 }} src={getImageLink("flex")} />
                      </OverlayTrigger>
                    </td>
                    {isTempCaptain("flex") ? (
                      <td>
                        <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["flex"])}/>
                        {" " + tempTeam.flex.player_name}
                        <Image style={{ width: 20, height: 20}} src={"https://i.imgur.com/ILor5VB.png"}/>
                      </td>
                    ) : (
                      <td>
                        <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["flex"])}/>
                        {" " + tempTeam.flex.player_name}
                      </td>
                    )}
                    {isTempCaptain("flex") ? (
                      canSwapPlayer("flex") ? (
                        <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.flex, selectedPlayer, "flex", selectedPos, props.mw)}>
                            Swap
                          </Button>
                        </td>
                      ) : (
                        <td width="400">
                          <Button variant="info" onClick={() => playerSelected("flex")}>
                            Select
                          </Button>
                      </td>
                      )

                    ) : (
                      canSwapPlayer("flex") ? (
                        <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.flex, selectedPlayer, "flex", selectedPos, props.mw)}>
                            Swap
                          </Button>
                          <Button variant="outline-warning" onClick={() => setNewCaptain("flex")}>
                            Set Captain
                          </Button>
                        </td>
                      ) : (
                        <td width="400">
                        <Button variant="info" onClick={() => playerSelected("flex")}>
                          Select
                        </Button>
                        <Button variant="outline-warning" onClick={() => setNewCaptain("flex")}>
                          Set Captain
                        </Button>
                      </td>
                      )

                    )}
                  </tr>

                </tbody>
              </Table>

              <Table striped bordered hover variant="dark">
                <tbody>
                  <tr>
                    <td width="50">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip" {...props}>
                            {"Bench"}
                          </Tooltip>
                        }
                      >
                        <Image style={{ width: 30, height: 30 }} src={getImageLink("bench")} />
                      </OverlayTrigger>
                    </td>
                    <td>
                      <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["bench_1"])}/>
                      {" " + tempTeam.bench_1.player_name}
                    </td>

                    {canSwapPlayer("bench_1") ? (
                      <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.bench_1, selectedPlayer, "bench_1", selectedPos, props.mw)}>
                            Swap
                          </Button>
                      </td>
                    ) : (
                      <td width="400">
                        <Button variant="info" onClick={() => playerSelected("bench_1")}>
                          Select
                        </Button>
                      </td>
                    )}

                  </tr>

                  <tr>
                    <td width="50">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip" {...props}>
                            {"Bench"}
                          </Tooltip>
                        }
                      >
                        <Image style={{ width: 30, height: 30 }} src={getImageLink("bench")} />
                      </OverlayTrigger>
                    </td>
                    <td>
                      <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["bench_2"])}/>
                      {" " + tempTeam.bench_2.player_name}
                    </td>
                    {canSwapPlayer("bench_2") ? (
                      <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.bench_2, selectedPlayer, "bench_2", selectedPos, props.mw)}>
                            Swap
                          </Button>
                      </td>
                    ) : (
                      <td width="400">
                        <Button variant="info" onClick={() => playerSelected("bench_2")}>
                          Select
                        </Button>
                      </td>
                    )}
                  </tr>

                  <tr>
                    <td width="50">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip" {...props}>
                            {"Bench"}
                          </Tooltip>
                        }
                      >
                        <Image style={{ width: 30, height: 30 }} src={getImageLink("bench")} />
                      </OverlayTrigger>
                    </td>
                    <td>
                      <Image style={{ width: 30, height: 30}} src={getImageLink(tempTeamList["bench_3"])}/>
                      {" " + tempTeam.bench_3.player_name}
                    </td>
                    {canSwapPlayer("bench_3") ? (
                      <td width="400">
                          <Button variant="danger" onClick={() => swapTempPlayer(tempTeam.bench_3, selectedPlayer, "bench_3", selectedPos, props.mw)}>
                            Swap
                          </Button>
                      </td>
                    ) : (
                      <td width="400">
                        <Button variant="info" onClick={() => playerSelected("bench_3")}>
                          Select
                        </Button>
                      </td>
                    )}
                  </tr>

                </tbody>
              </Table>

            </div>
          )}
          </div>
        )}
        {props.istotal? (
          <div style={{ float: "right" }}>
          <Card style={{ width: "100%" }} bg="dark" text="white" border="secondary">
            <Card.Header as="h5">Total Points</Card.Header>
            <Card.Body style={{ height: 50 }}>
              <Card.Text>
                <div style={{ fontSize: 20, display: "flex", justifyContent: "center", alignItems: "center", height: "10px" }}>
                  {totalPoints}
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        ) : (
          <>
          {expired ? (
            <div style={{ float: "left" }}>
              <Card style={{ width: "100%" }} bg="danger" text="white" border="secondary">
                <Card.Header as="h6">Match Day Started</Card.Header>
              </Card>
            </div>
          ) : (
            editMode ? (
              <div style ={{float: "left"}}>
                <Button variant="success" onClick={() => saveLineup()}>
                  Save Lineup
                </Button>
                <Button variant="outline-danger" onClick={() => clearSelection()}>
                  Clear Selection
                </Button>
              </div>
            ) : (
              <div style={{ float: "left" }}>
              <Card style={{ width: "100%" }} bg="dark" text="white" border="secondary">
                <Card.Header as="h6">{timer}</Card.Header>
                <Card.Body>
                  <Button variant="primary" onClick={() => startEdit()}>
                    Edit Lineup
                  </Button>
                </Card.Body>
              </Card>
            </div>
            )

          )}
          

            <div style={{ float: "right" }}>
              <Card style={{ width: "100%" }} bg="dark" text="white" border="secondary">
                <Card.Header as="h5">Total Points</Card.Header>
                <Card.Body style={{ height: 50 }}>
                  <Card.Text>
                    <div style={{ fontSize: 20, display: "flex", justifyContent: "center", alignItems: "center", height: "10px" }}>
                      {totalPoints}
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          
            </>

        )}



    </div>
  );
}
