// import React, { useContext } from 'react'
// // import React, { useContext, useEffect, useState } from 'react'
// import { Navigate, Outlet } from 'react-router-dom'
// import { UserContext } from '../provider/UserProvider'
// // import Dashboard from './Dashboard'
// // import { doesUserExist, getUserTeamByUID } from '../components/Firebase'
// // import Leaderboard from './Leaderboard'
// import SearchPlayers from '../components/SearchPlayers'

// export default function DashboardRouting(props) {
//     const user = useContext(UserContext)
//     // const [userTeam, setUserTeam] = useState(null)
//     // const [userExist, setUserExist] = useState(false)

//     // useEffect(() => {
//     //     let isMounted = true;               // note mutable flag
//     //     if (user !== null) {
//     //         doesUserExist(user).then((exists) => {
//     //             if (isMounted) setUserExist(exists);    // add conditional check
//     //         })
//     //     }

//     //     return () => { isMounted = false }; // cleanup toggles value, if unmounted
//     // }, [user]);

//     // useEffect(() => {
//     //     if (userExist) {
//     //         // send stuff to dashboard props
//     //         getUserTeamByUID(user).then((team) => {
//     //             setUserTeam(team)
//     //         })
//     //     }
//     // }, [userExist, user])

//     // return user ? userExist
//     //     ? <Dashboard userTeam={userTeam} uid={user}/>
//     //     : <Leaderboard />
//     //     : <Navigate to='/login' />
//     return user ? props.exist ? <Outlet/> : <SearchPlayers createNewUser={props.create}/> : <Navigate to='/login'/>
// }

// import React, { useContext } from 'react'
import React, { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { UserContext } from '../provider/UserProvider'
import Dashboard from './Dashboard'
import { doesUserExist, getUserTeamByUID } from '../components/Firebase'
import Leaderboard from './Leaderboard'
// import SearchPlayers from '../components/SearchPlayers'

export default function DashboardRouting(props) {
    const user = useContext(UserContext)
    const [userTeam, setUserTeam] = useState(null)
    const [userExist, setUserExist] = useState(false)

    useEffect(() => {
        let isMounted = true;               // note mutable flag
        if (user !== null) {
            doesUserExist(user).then((exists) => {
                if (isMounted) setUserExist(exists);    // add conditional check
            })
        }

        return () => { isMounted = false }; // cleanup toggles value, if unmounted
    }, [user]);

    useEffect(() => {
        if (userExist) {
            // send stuff to dashboard props
            getUserTeamByUID(user).then((team) => {
                setUserTeam(team)
            })
        }
    }, [userExist, user])

    return user ? userExist
        ? <Dashboard userTeam={userTeam} uid={user}/>
        : <Leaderboard />
        : <Navigate to='/login' />
    // return user ? props.exist ? <Outlet/> : <SearchPlayers createNewUser={props.create}/> : <Navigate to='/login'/>
}