import React, { useEffect, useState, useContext } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card"
import StatTable from "../components/StatTable";
import { getUserMatchInfo, getUserTeamName, setUserPoints, setUserSpecificPlayerPoints } from "../components/Firebase";
import Banner from "../components/Banner";
import { UserContext } from "../provider/UserProvider";

// function handleSelect(key) {
//   var matchDayNum = "MatchDay" + "" + key;
//   console.log(matchDayNum)
// }

export default function Dashboard(props) {

  const user = useContext(UserContext)

  var totalDefault = {
    duelist_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    duelist_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    controller_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    controller_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    initiator_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    initiator_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    sentinel_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    sentinel_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    bench_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    bench_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
    bench_3: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 }
  }

  const [userTeam, setUserTeam] = useState(props.userTeam)
  const [matchday1, setMatchDay1] = useState(null)
  const [matchday2, setMatchDay2] = useState(null)
  const [matchday3, setMatchDay3] = useState(null)
  const [matchday4, setMatchDay4] = useState(null)
  const [matchday5, setMatchDay5] = useState(null)
  const [matchday6, setMatchDay6] = useState(null)
  const [matchday7, setMatchDay7] = useState(null)
  const [totalStat, setTotalStat] = useState(totalDefault)
  const [userTeamName, setUserTeamName] = useState("")

  async function setData(matchNum, data) {
    switch (matchNum) {
      case (1):
        await setMatchDay1(data)
        break
      case (2):
        await setMatchDay2(data)
        break
      case (3):
        await setMatchDay3(data)
        break
      case (4):
        await setMatchDay4(data)
        break
      case (5):
        await setMatchDay5(data)
        break
      case (6):
        await setMatchDay6(data)
        break
      case (7):
        await setMatchDay7(data)
        break
      default: return
    }
  }

  useEffect(() => {
    setUserTeam(props.userTeam)
  }, [props.userTeam])

  useEffect(() => {
    
    async function fetchData() {
      try {
        var name = getUserTeamName(props.uid)
        name.then((value) => {
          setUserTeamName(value)
        })
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [props.uid])

  useEffect(() => {
    if (userTeam !== null) {
      
      async function fetchMatchData(match, matchNum) {
        var duelist = await getUserMatchInfo(match, userTeam["team_" + matchNum].duelist.team, userTeam["team_" + matchNum].duelist.name)
        var controller = await getUserMatchInfo(match, userTeam["team_" + matchNum].controller.team, userTeam["team_" + matchNum].controller.name)
        var initiator = await getUserMatchInfo(match, userTeam["team_" + matchNum].initiator.team, userTeam["team_" + matchNum].initiator.name)
        var sentinel = await getUserMatchInfo(match, userTeam["team_" + matchNum].sentinel.team, userTeam["team_" + matchNum].sentinel.name)
        var flex = await getUserMatchInfo(match, userTeam["team_" + matchNum].flex.team, userTeam["team_" + matchNum].flex.name)
        var bench1 = await getUserMatchInfo(match, userTeam["team_" + matchNum].bench_1.team, userTeam["team_" + matchNum].bench_1.name)
        var bench2 = await getUserMatchInfo(match, userTeam["team_" + matchNum].bench_2.team, userTeam["team_" + matchNum].bench_2.name)
        var bench3 = await getUserMatchInfo(match, userTeam["team_" + matchNum].bench_3.team, userTeam["team_" + matchNum].bench_3.name)
        var captain = userTeam["team_" + matchNum].captain

        const matchData = {
          duelist: duelist,
          controller: controller,
          initiator: initiator,
          sentinel: sentinel,
          flex: flex,
          bench_1: bench1,
          bench_2: bench2,
          bench_3: bench3,
          captain
        }
        await setData(matchNum, matchData);
      }
      fetchMatchData("MatchDay1", 1);
      fetchMatchData("MatchDay2", 2);
      fetchMatchData("MatchDay3", 3);
      fetchMatchData("MatchDay4", 4);
      fetchMatchData("MatchDay5", 5);
      fetchMatchData("MatchDay6", 6);
      fetchMatchData("MatchDay7", 7);
    }

  }, [userTeam, user])

  useEffect(() => {
    var defaultTeam = {
      duelist_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      duelist_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      controller_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      controller_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      initiator_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      initiator_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      sentinel_1: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 },
      sentinel_2: { a: 0, d: 0, fd: 0, fk: 0, k: 0, player_name: "", points: 0 }
    }

    
    if (matchday1 !== null && matchday2 !== null && matchday3 !== null && matchday4 !== null && matchday5 !== null && matchday6 !== null && matchday7 !== null && userTeam !== null && userTeam.new_transfers !== null) {
      defaultTeam.controller_1.player_name = userTeam.controller_1.name
      defaultTeam.controller_2.player_name = userTeam.controller_2.name
      defaultTeam.duelist_1.player_name = userTeam.duelist_1.name
      defaultTeam.duelist_2.player_name = userTeam.duelist_2.name
      defaultTeam.initiator_1.player_name = userTeam.initiator_1.name
      defaultTeam.initiator_2.player_name = userTeam.initiator_2.name
      defaultTeam.sentinel_1.player_name = userTeam.sentinel_1.name
      defaultTeam.sentinel_2.player_name = userTeam.sentinel_2.name

      let matchdays = {
        matchday1,
        matchday2,
        matchday3,
        matchday4,
        matchday5,
        matchday6,
        matchday7
      }
      let days = Object.keys(matchdays)
      days.forEach((day) => {
        //console.log(matchdays[day].bench_1.player_name)
        let positions = Object.keys(matchdays[day])
        positions.forEach((pos) => {
          let playerObj = matchdays[day][pos]

          switch (playerObj.player_name) {

            case (userTeam.controller_1.name):
              if (pos !== "bench_1" && pos !== "bench_2" && pos !== "bench_3") {
                defaultTeam.controller_1.k += playerObj.k
                defaultTeam.controller_1.d += playerObj.d
                defaultTeam.controller_1.a += playerObj.a
                defaultTeam.controller_1.fd += playerObj.fd
                defaultTeam.controller_1.fk += playerObj.fk
                defaultTeam.controller_1.points += playerObj.points
              }
              if (pos === matchdays[day].captain) {
                defaultTeam.controller_1.points += Math.round(playerObj.points / 2)
              }

              setUserSpecificPlayerPoints(1, defaultTeam.controller_1.points, user)

            break

            case (userTeam.controller_2.name):
              if (pos !== "bench_1" && pos !== "bench_2" && pos !== "bench_3") {
                defaultTeam.controller_2.k += playerObj.k
                defaultTeam.controller_2.d += playerObj.d
                defaultTeam.controller_2.a += playerObj.a
                defaultTeam.controller_2.fd += playerObj.fd
                defaultTeam.controller_2.fk += playerObj.fk
                defaultTeam.controller_2.points += playerObj.points
              }
              if (pos === matchdays[day].captain) {
                defaultTeam.controller_2.points += Math.round(playerObj.points / 2)
              }

              setUserSpecificPlayerPoints(2, defaultTeam.controller_2.points, user)

            break

            case(userTeam.duelist_1.name):
              if (pos !== "bench_1" && pos !== "bench_2" && pos !== "bench_3") {
                defaultTeam.duelist_1.k += playerObj.k
                defaultTeam.duelist_1.d += playerObj.d
                defaultTeam.duelist_1.a += playerObj.a
                defaultTeam.duelist_1.fd += playerObj.fd
                defaultTeam.duelist_1.fk += playerObj.fk
                defaultTeam.duelist_1.points += playerObj.points
              }
              if (pos === matchdays[day].captain) {
                defaultTeam.duelist_1.points += Math.round(playerObj.points / 2)
              }

              setUserSpecificPlayerPoints(3, defaultTeam.duelist_1.points, user)

            break

            case(userTeam.duelist_2.name):
              if (pos !== "bench_1" && pos !== "bench_2" && pos !== "bench_3") {
                defaultTeam.duelist_2.k += playerObj.k
                defaultTeam.duelist_2.d += playerObj.d
                defaultTeam.duelist_2.a += playerObj.a
                defaultTeam.duelist_2.fd += playerObj.fd
                defaultTeam.duelist_2.fk += playerObj.fk
                defaultTeam.duelist_2.points += playerObj.points
              }
              if (pos === matchdays[day].captain) {
                defaultTeam.duelist_2.points += Math.round(playerObj.points / 2)
              }
              setUserSpecificPlayerPoints(4, defaultTeam.duelist_2.points, user)

            break

            case(userTeam.initiator_1.name):
              if (pos !== "bench_1" && pos !== "bench_2" && pos !== "bench_3") {
                defaultTeam.initiator_1.k += playerObj.k
                defaultTeam.initiator_1.d += playerObj.d
                defaultTeam.initiator_1.a += playerObj.a
                defaultTeam.initiator_1.fd += playerObj.fd
                defaultTeam.initiator_1.fk += playerObj.fk
                defaultTeam.initiator_1.points += playerObj.points
              }
              if (pos === matchdays[day].captain) {
                defaultTeam.initiator_1.points += Math.round(playerObj.points / 2)
              }

              setUserSpecificPlayerPoints(5, defaultTeam.initiator_1.points, user)

            break

            case(userTeam.initiator_2.name):
              if (pos !== "bench_1" && pos !== "bench_2" && pos !== "bench_3") {
                defaultTeam.initiator_2.k += playerObj.k
                defaultTeam.initiator_2.d += playerObj.d
                defaultTeam.initiator_2.a += playerObj.a
                defaultTeam.initiator_2.fd += playerObj.fd
                defaultTeam.initiator_2.fk += playerObj.fk
                defaultTeam.initiator_2.points += playerObj.points
              }
              if (pos === matchdays[day].captain) {
                defaultTeam.initiator_2.points += Math.round(playerObj.points / 2)
              }
              setUserSpecificPlayerPoints(6, defaultTeam.initiator_2.points, user)

            break

            case(userTeam.sentinel_1.name):
              if (pos !== "bench_1" && pos !== "bench_2" && pos !== "bench_3") {
                defaultTeam.sentinel_1.k += playerObj.k
                defaultTeam.sentinel_1.d += playerObj.d
                defaultTeam.sentinel_1.a += playerObj.a
                defaultTeam.sentinel_1.fd += playerObj.fd
                defaultTeam.sentinel_1.fk += playerObj.fk
                defaultTeam.sentinel_1.points += playerObj.points
              }
              if (pos === matchdays[day].captain) {
                defaultTeam.sentinel_1.points += Math.round(playerObj.points / 2)
              }
              setUserSpecificPlayerPoints(7, defaultTeam.sentinel_1.points, user)

            break

            case(userTeam.sentinel_2.name):
              if (pos !== "bench_1" && pos !== "bench_2" && pos !== "bench_3") {
                defaultTeam.sentinel_2.k += playerObj.k
                defaultTeam.sentinel_2.d += playerObj.d
                defaultTeam.sentinel_2.a += playerObj.a
                defaultTeam.sentinel_2.fd += playerObj.fd
                defaultTeam.sentinel_2.fk += playerObj.fk
                defaultTeam.sentinel_2.points += playerObj.points
              }
              if (pos === matchdays[day].captain) {
                defaultTeam.sentinel_2.points += Math.round(playerObj.points / 2)
              }
              setUserSpecificPlayerPoints(8, defaultTeam.sentinel_2.points, user)

            break
            default: return
          }
          //console.log(matchdays[day][pos])
        })
        //let c1Pos = findTeamPosition(userTeam.controller_1.name, matchdays[day])
      })
      setTotalStat(defaultTeam)

      var totalPoints = defaultTeam.controller_1.points + 
      defaultTeam.controller_2.points + 
      defaultTeam.duelist_1.points + 
      defaultTeam.duelist_2.points + 
      defaultTeam.initiator_1.points + 
      defaultTeam.initiator_2.points + 
      defaultTeam.sentinel_1.points + 
      defaultTeam.sentinel_2.points
      setUserPoints(user, totalPoints)
    }
  }, [matchday1, matchday2, matchday3, matchday4, matchday5, matchday6, matchday7, userTeam, user])

  return (
    <div
      style={{
        backgroundColor: "#0e1822",
        backgroundSize: "cover",
        minHeight: "100vh", height: "100%", width: "100vw"
      }}
    >
      <Banner/>
      <br />
      <div style={{ float: "left" , paddingLeft: "15%"}}>
        
      </div>

      <div style={{ paddingLeft: "15%", paddingRight: "15%" }}>
      <Card style={{ width: "50%" }} bg="dark" text="white" border="secondary">
        {/* <Card.Title as="h5">Team Name: </Card.Title> */}
          <Card.Body style={{ height: 50 }}>
            <Card.Text>
              <div style={{ fontSize: 20, display: "flex", justifyContent: "center", alignItems: "center", height: "10px" }}>
                {userTeamName}
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <br />
        <Tabs defaultActiveKey="0" id="dashboard" className="mb-3">
          <Tab eventKey="0" title="Total">
            <StatTable istotal={true} teamdata={totalStat} userteam={props.userTeam} mw={0}/>
          </Tab>
          <Tab eventKey="1" title="Group Stage">
            <StatTable istotal={false} teamdata={matchday1} userteam={props.userTeam} mw={1}/>
          </Tab>
          <Tab eventKey="2" title="Upper QF">
            <StatTable istotal={false} teamdata={matchday2} userteam={props.userTeam} mw={2}/>
          </Tab>
          <Tab eventKey="3" title="Upper SF/Lower R1">
            <StatTable istotal={false} teamdata={matchday3} userteam={props.userTeam} mw={3}/>
          </Tab>
          <Tab eventKey="4" title="Lower R2">
            <StatTable istotal={false} teamdata={matchday4} userteam={props.userTeam} mw={4}/>
          </Tab>
          <Tab eventKey="5" title="Upper F/Lower R3">
            <StatTable istotal={false} teamdata={matchday5} userteam={props.userTeam} mw={5}/>
          </Tab>
          <Tab eventKey="6" title="Lower F">
            <StatTable istotal={false} teamdata={matchday6} userteam={props.userTeam} mw={6}/>
          </Tab>
          <Tab eventKey="7" title="Grand F">
            <StatTable istotal={false} teamdata={matchday7} userteam={props.userTeam} mw={7}/>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}