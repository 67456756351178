import React from 'react'
import {
    auth,
    provider,
} from '../components/Firebase'
import { signInWithPopup } from "firebase/auth";
import { Card, Image, Button } from 'react-bootstrap'


export default function LoginSignUp(props) {

    function signIn() {
        signInWithPopup(auth, provider).then(async (result) => {
        }).catch((error) => {
            console.log(error)
        });
    }


    return (
        <div style={{ textAlign: 'center' }} >
            <Card style={{ height: '100vh' }} border="light">
                <Card.Header><Image style={{ width: "100%"}}src="https://i.imgur.com/3GOLrLR.png"/></Card.Header>
                <Card.Body>
                    {/* <Card.Subtitle>Sign In to Create Your Team for Fantasy VCT</Card.Subtitle> */}
                    <Card.Subtitle>Team Registration has closed. Sign in to view the leaderboard.</Card.Subtitle>
                    <br />


                    <Button variant="outline-light" size="sm"><img src="https://i.imgur.com/wdaKPhZ.png"
                        alt="hi" onClick={() => signIn()} /></Button>


                </Card.Body>
            </Card>
        </div>
    )
}