import { React, createContext, useEffect, useState } from 'react'
import { auth } from '../components/Firebase'

export const UserContext = createContext({ user: null })


export default function UserProvider(props) {
    const [user, setUser] = useState(null)
    const [lastAuth, setLastAuth] = useState(null)


    useEffect(() => {
        auth.onAuthStateChanged(async userAuth => {
            if (lastAuth === userAuth) {
                return
            } else {
                setLastAuth(userAuth)
                setUser(userAuth.uid)
            }
        })
    },[lastAuth])

    return (
        <UserContext.Provider value={user}>
            {props.children}
        </UserContext.Provider>
    )
}