import React from "react";
import Image from "react-bootstrap/Image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getImageLink } from "./SearchPlayers";

export default function PlayerRow(props) {

  return (
    <>
      {props.istotal ? (
        <tr>
          <td>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  {props.rolename}
                </Tooltip>
              }
            >
              <Image style={{ width: 30, height: 30 }} src={props.imagelink} />
            </OverlayTrigger>
          </td>
          <td>
            <Image style={{ width: 30, height: 30}} src={getImageLink(props.team)}/>
            {" " + props.player.player_name}
          </td>
          <td>{props.player.k}</td>
          <td>{props.player.d}</td>
          <td>{props.player.a}</td>
          <td>{props.player.fk}</td>
          <td>{props.player.fd}</td>
          <td>{props.player.points}</td>
        </tr>
      ) : (
        <tr>
          <td width="60">
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip" {...props}>
                  {props.rolename}
                </Tooltip>
              }
            >
              <Image style={{ width: 30, height: 30 }} src={props.imagelink} />
            </OverlayTrigger>
          </td>
          {props.iscaptain ? (
            <td>
              <Image style={{ width: 30, height: 30}} src={getImageLink(props.team)}/>
              {" " + props.player.player_name + " "}
              <Image style={{ width: 20, height: 20}} src={"https://i.imgur.com/ILor5VB.png"}/>
            </td>
          ) : (
            <td>
              <Image style={{ width: 30, height: 30}} src={getImageLink(props.team)}/>
              {" " + props.player.player_name}
            </td>
          )}

          <td width="80">{props.player.k}</td>
          <td width="80">{props.player.d}</td>
          <td width="80">{props.player.a}</td>
          <td width="80">{props.player.fk}</td>
          <td width="80">{props.player.fd}</td>
          <td width="80">{props.player.adr}</td>
          {props.iscaptain ? (
            <td width="80">{props.player.points + Math.round(props.player.points / 2)}</td>
            ) : (
            <td width="80">{props.player.points}</td>
            )}
        </tr>
      )}
    </>
  );
}
